import React from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import LayoutExtra from "../components/layoutExtra"
import { Button } from 'react-bootstrap'

const Thanks = () => {

  const data = useStaticQuery(graphql`
    query {
			check: file(relativePath: { eq: "icons/check.svg" }) {
        publicURL
      },
    }
	`)

  return (
    <LayoutExtra>
      <SEO title="送信完了" />
      <div className="page-head">
        <div className="h2-subtitle">
          <img src={data.check.publicURL} alt="送信完了" />
          <h2>送信完了</h2>
          <p>Your message has been sent</p>
        </div>
      </div>

      <div className="text-center">
        <p>
          送信が完了しました。<br />
          後日弊社担当よりご返信させて頂きます。
        </p>
        <Button
          variant="info"
          className="mt-5"
          onClick={() => navigate("/")}
        >
          トップページに戻る
        </Button>
      </div>

    </LayoutExtra>
  )
}

export default Thanks